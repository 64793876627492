import {ref, computed, watchEffect, onMounted, onBeforeUnmount, watch} from "vue";
import http from "@/http";
import eventBus from "@/eventBus";
import applications from "@/views/Methodes.vue"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useApplicationMethodeList(props) {
    const perPage = ref(10);
    const currentPage = ref(1);
    const selectMode = ref("single");
    const showModalAfficher = ref(false);
    const showModalCaractEtape = ref(false);
    const showModalEtapes = ref(false);
    const showModalMethode = ref(false);
    const etapesMeth = ref([]);
    const idApplication = ref(null);
    const idMethode = ref(null);
    const filtre_applications = ref("");
    const selectedApplicationId = ref(null);
    const nbResult = ref(0);
    const totalRows = computed(() => props.applications.length);

    const fields = [
        {key: 'selection', label:'Sélection', class: 'text-center'},
        {key: 'idApplication', label:'Id', class: 'text-center'},
        {key: 'titre', label:'Titre', class: 'text-center'},
        {key: 'annee', label:'Année', class: 'text-center'},
        {key: 'idMethode', label:'Id Meth.', class: 'text-center'},
    ];

    const filteredApplications = computed(() => {
        if (!filtre_applications.value) return props.applications;
        return props.applications.filter(
            (application) =>
                (application.titre &&
                    application.titre.toLowerCase().includes(filtre_applications.value.toLowerCase())) ||
                (application.annee && application.annee.toString().includes(filtre_applications.value))
        );
    });
    watch(selectedApplicationId, (newVal) => {
        if (newVal !== null) {
            eventBus.emit("updateApplicationSelected", newVal);
        }
    });
    watchEffect(() => {
        nbResult.value = filteredApplications.value.length;
        currentPage.value = 1;
    });

    const paginatedApplications = computed(() => {
        const start = (currentPage.value - 1) * perPage.value;
        const end = start + perPage.value;
        return filteredApplications.value.slice(start, end);
    });

    const selectApplication = (applicationId) => {
        selectedApplicationId.value = applicationId;
        http.loadApplication(applicationId).then((data) => {
            idMethode.value = data.idMethode;
        })
    };

    const getEtapesMeth = (applicationId) =>{
        http.getEtapesByMethodeInApp(applicationId).then((data)=>{
            selectedApplicationId.value = applicationId;
            etapesMeth.value = data;
        })
    }

    const getApplicationsMethode = () => {
        http.getApplicationsMethode().then((data) => {
            applications.value = data;
        });
    };

    onMounted(() => {
        nbResult.value = 0;
        eventBus.on("reloadApplicationsMethode", getApplicationsMethode);
        eventBus.on("updateApplicationSelected", getEtapesMeth);
        eventBus.on('reloadEtapesMeth', getEtapesMeth);

        onBeforeUnmount(() => {
            eventBus.off("reloadApplicationsMethode", getApplicationsMethode);
            eventBus.off('reloadEtapesMeth', getEtapesMeth);
            eventBus.off('updateApplicationSelected', getEtapesMeth);

        });
    });

    return {
        perPage,
        currentPage,
        selectMode,
        showModalAfficher,
        showModalCaractEtape,
        showModalMethode,
        showModalEtapes,
        etapesMeth,
        idApplication,
        idMethode,
        filtre_applications,
        nbResult,
        fields,
        filteredApplications,
        paginatedApplications,
        selectedApplicationId,
        totalRows,
        selectApplication,
    };
}