<template>
  <div class="etapeList">
    <NotificationMessage v-if="showNotification" :message="notificationMessage" :type="notificationType" :duration="5000"/>
    <div class="tableSecondElement">
      <table id="tableEtape" class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="etape in etapesMeth" :key="etape.idEtape">
            <td class="text-center">{{ etape.noEtape }}</td>
            <td class="text-center">{{ etape.nom }}</td>
            <td class="text-center"><b-button type="button" size="sm" @click="showModal(etape)" id="modifEtape" class="btn-update"><i class="fa-solid fa-pen-to-square"></i></b-button></td>
            <td class="text-center"><b-button type="button" size="sm" @click="deleteEtape(etape)" id="deleteEtape" class="btn-delete"><i class="fas fa-trash-alt"></i></b-button></td>
          </tr>
        </tbody>
      </table>

    </div>
    <div id="my-modal20">
        <ModalModif :id="idEtape" :idApp="idApp" @close="showModalModif = false" v-if="showModalModif" @notification="handleNotification">
        </ModalModif>
    </div>
  </div>
</template>

<script>
import http from "@/http";
import eventBus from '@/eventBus.js'
import { defineComponent, ref} from 'vue';
import ModalModif from "@/components/Methode/Etape/ModalModifierEtape.vue"
import NotificationMessage from "@/components/Notification.vue";

export default defineComponent({
  name: "EtapesList",
  components: {NotificationMessage, ModalModif},

  props:{
    etapesMeth:{
      type:Array,
      required:true
    },
    idApp:{
      type:Number,
      required:true
    },
    lectureSeule:{
      type:Boolean,
      default:true
    },
  },

  setup(props) {
    const fields = ref([
      {key: 'noEtape', label: 'Numéro', class: 'text-center'},
      {key: 'nom', label: 'Nom', class: 'text-center'},
      {key: 'update', label: 'Modifier', class: 'text-center'},
      {key: 'delete', label: 'Supprimer', class: 'text-center'}
    ]);

    const showModalModif = ref(false);
    const idEtape = ref(null);

    const showNotification = ref(false);
    const notificationMessage = ref("");
    const notificationType = ref("info");

    const handleNotification = ({ message, type }) => {
      notificationMessage.value = message;
      notificationType.value = type;
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };

    const deleteEtape = async (etape) => {
      if (confirm(`Voulez vous vraiment supprimer l'étape : ${etape.noEtape} "${etape.nom}"`)) {
        try{
          await http.deleteEtape(etape.idEtape);
          eventBus.emit("reloadEtapesMeth", props.idApp);
          eventBus.emit("notification", {
            message: "L'étape a été supprimée avec succès",
            type: "success"
          });
        } catch (error) {
          eventBus.emit("notification", {
            message: "Erreur lors de la suppression de l'étape",
            type: "danger"
          });
        }
      }
    }

    const showModal = (etape) => {
      idEtape.value = etape.idEtape;
      showModalModif.value = ! showModalModif.value;
    }

    return {
      showModal,
      deleteEtape,
      fields,
      idEtape,
      showModalModif,
      showNotification,
      notificationMessage,
      notificationType,
      handleNotification
    }
  }
})


</script>

<style scoped>
.btn-delete{
  color: white !important;
  font-size: 10px;
  text-align: right;
  margin:auto;
  background-color: #ed6e6c!important;
}
.btn-delete:hover{
  color: black !important;
}
.btn-update{
  color: white !important;
  font-size: 10px;
  text-align: right;
  margin:auto;
  background-color: #275662!important;
}
.btn-update:hover{
  color: black !important;
}

</style>