import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import http from '@/http.js';
import eventBus from '@/eventBus.js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useModifDonnees(props, emit) {
    const locale = ref("fr-FR");
    const idDonnee = ref("");
    const type = ref("");
    const sousType = ref("");
    const source = ref("");
    const echelleGeo = ref("");
    const echelleTemp = ref("");
    const typeValeur = ref("");
    const donneesData = ref({});
    const donneeSol = ref(false);
    const localId = ref(props.id);
    const options = ref([
        { value: 'Quantitative', text: 'Quantitative' },
        { value: 'Qualitative', text: 'Qualitative' }
    ]);

    const message = ref("");
    const showNotification = ref(false);
    const messageType = ref("info");
    const duration = ref(5000);

    const schema = yup.object({
        type: yup.string().required('Ce champ est obligatoire'),
    });

    const { handleSubmit } = useForm({
        validationSchema: schema
    });

    const updateModalData = async (idDonnee) => {
        try {
            const data = await http.loadDonnees(idDonnee);
            type.value = data.type;
            sousType.value = data.sousType;
            source.value = data.source;
            echelleGeo.value = data.echelleGeo;
            echelleTemp.value = data.echelleTemp;
            typeValeur.value = data.typeValeur;
            donneeSol.value = data.donneeSol;
        } catch (error) {
            console.error("Failed to load donnee:", error);
        }
    };

    onMounted(async () => {
        if (props.id) {
            await updateModalData(props.id);
        }
        eventBus.on('updateDonneeSelected', updateModalData);
    });

    onBeforeUnmount(() => {
        eventBus.off('updateDonneeSelected', updateModalData);
    });

    const emitModifierDonnees = handleSubmit(async (values) => {
        donneesData.value = {
            idDonnee: localId.value,
            type: values.type,
            sousType: sousType.value,
            donneeSol: donneeSol.value,
            source: source.value,
            echelleGeo: echelleGeo.value,
            echelleTemp: echelleTemp.value,
            typeValeur: typeValeur.value,
        };

        try {
            const data = await http.updateDonnees(donneesData.value);
            localId.value = data;
            emit('close');
            eventBus.emit('reloadDonnees');
            emit('notification',{
                showNotification : true,
                message : "Donnée modifiée avec succès!",
                messageType : "success",
            });
            eventBus.emit('donneeModifie', donneesData.value);
        } catch (error) {
            emit('notification',{
                showNotification : true,
                message : 'Erreur lors de la modification de la donnée.',
                messageType : "danger",
            })
        }
    });

    return {
        locale,
        idDonnee,
        type,
        sousType,
        source,
        echelleGeo,
        echelleTemp,
        typeValeur,
        options,
        donneeSol,
        donneesData,
        emitModifierDonnees,
        message,
        showNotification,
        messageType,
        duration
    };
}