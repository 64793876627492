<template>
  <transition name="modal">
    <b-card class="modal-container">
        <div class="mr-4 ml-4 mb-3">
          <b-row>
            <b-col cols="3">
              <b-form-group class="labelTitre" id="input-group-1" style="text-align: start" label-size="sm" label="ID Meth:" label-for="input-1">
                <b-form-input v-if="idMethode!==0" id="input-1" style="width: 60%" v-model="idMethode" :readonly="lectureSeule" size="sm" type="text">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <form @submit.prevent="emitInsertMethode" id="saveMethode">
          <div>
            <b-card class="formModal mr-4 ml-4">
                <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Libellé de la méthode*:" label-for="textarea">
                  <Field name="libelleMethode" as="textarea" v-model="libelleMethode" class="form-control" />
                  <ErrorMessage name="libelleMethode" class="text-danger" />
                </b-form-group>

              <b-row>
                <b-col cols="6">
                  <b-form-group class="labelTitre" id="input-group-3" label-size="sm" label="Résolution:" label-for="input-3">
                    <b-form-select id="input-3" size="sm" v-model="resolution.code">
                      <option :value="null"></option>
                      <option v-for="r in resolutions" v-bind:value="r.code" v-bind:key="r.code">{{r.signification}}</option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group class="labelTitre" id="input-group-4" label-size="sm" label="Facilite:" label-for="input-4">
                <b-form-select id="input-4" size="sm" v-model="facilite.code">
                  <option :value="null"></option>
                  <option v-for="f in facilites" v-bind:value="f.code" v-bind:key="f.code">{{f.signification}}</option>
                </b-form-select>
              </b-form-group>

              <b-form-group class="labelTitre" id="input-group-5" label-size="sm" label="Validation:" label-for="input-5">
                <b-form-select id="input-5" size="sm" v-model="validation.code">
                  <option :value="null"></option>
                  <option v-for="v in validations" v-bind:value="v.code" v-bind:key="v.code">{{v.signification}}</option>
                </b-form-select>
              </b-form-group>

              <b-row>
                <b-col cols="6">
                  <b-form-group class="labelTitre" label-size="sm" label="Combinaison méthodes traitements (o/n):" label-for="checkbox-1">
                    <b-form-checkbox id="checkbox-1" v-model="combineMethTrait">
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="labelTitre" label-size="sm" label="Résultats spatialisés (o/n):" label-for="checkbox-2">
                    <b-form-checkbox id="checkbox-2" v-model="spatialisationMethode">
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col sm="5">
                  <label class="labelTitre" for="textarea-small">Sélectionnez le ou les outils nécessaires à la méthode:</label>
                </b-col>
                <b-col sm="5">
                <b-form-group class="labelTitre" id="input-group-6" label-size="sm" label-for="input-6">
                  <b-form-select id="input-6" size="sm" v-model="outilsToAdd" multiple :select-size="4">
                    <option :value="null"></option>
                    <option v-for="o in outils" v-bind:value="o" v-bind:key="o.code">{{o.signification}}</option>
                  </b-form-select>
                </b-form-group>
                </b-col>
              </b-row>

            </b-card>
          </div>
          <div class="bouton">
            <b-button pill type="submit" v-if="idMethode!==0" class="valid m-2">Modifier la méthode</b-button>
            <b-button pill type="submit" v-else class="valid m-2">Enregistrer la méthode</b-button>
            <b-button pill  @click="reset" class="valid m-2">Réinitialiser les champs</b-button>
          </div>

        </form>

    </b-card>
  </transition>
</template>

<script>

import { Field, ErrorMessage } from 'vee-validate';
import {useFormMethode} from "@/composition/Methode/formMethode";

export default {
  name: 'SelectMethode',

  components: {
    Field,
    ErrorMessage
  },

  props:{
    idApplicationMeth:{
      type:Number
    },
    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props, { emit }) {
    return useFormMethode(props, emit);
  }
};
</script>

<style scoped>

.modal-container {
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

}

.labelTitre{
  padding-top: 5px;
  font-size: 14px;
}

</style>