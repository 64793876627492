<template>
  <div class="donneesList">
    <div>
      <NotificationMessage v-if="showNotification" :message="notificationMessage" :type="notificationType" :duration="5000"/>
    </div>
    <div v-if="showDonnees">
      <p style="padding-top: 10px"><strong>II. Type(s) de données utilisées dans l'étape de traitement</strong></p>
      <div class="tableElement">
        <table id="tableDonnees" class="table table-bordered table-striped table-hover table-sm">
          <thead>
            <tr>
              <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="donnee in paginatedDonnees" :key="donnee.idDonnee" @click="toggleSelected(donnee)"
                :class="{ 'table-active': isSelected(donnee.idDonnee) }">
              <td class="text-center">
                <input type="checkbox" v-model="selectedDonnees" :value="donnee.idDonnee" disabled>
              </td>
              <td class="text-center">{{ donnee.idDonnee }}</td>
              <td class="text-center">{{ donnee.type }}</td>
              <td class="text-center">{{ donnee.sousType }}</td>
              <td class="text-center">{{ donnee.echelleGeo }}</td>
              <td class="text-center">{{ donnee.echelleTemp }}</td>
              <td class="text-center">
                <input type="checkbox" :checked="donnee.donneeSol" disabled>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-row>
        <b-col cols="1" class="icon">
          <a class="icon" title="ajouter une nouvelle donnée"
            @click.prevent ="showModalAdd = !showModalAdd; showModalModif=false" align-self="center"><i class="fas fa-plus-circle" id="ajoutDonnee"></i></a>
        </b-col>
        <b-col cols="1" class="icon">
          <a class="icon" v-if="selectedDonneeId !== null" title="modifier la donnée sélectionnée"
            @click.prevent ="showModalModif = !showModalModif; showModalAdd=false" align-self="center"><i class="fa-solid fa-pen-to-square" id="modifDonnee"></i></a>
          <a class="icon" v-else title="modifier la donnée sélectionnée" @click="selection" align-self="center"><i class="fa-solid fa-pen-to-square" id="modifDonnee"></i></a>
        </b-col>
        <b-col cols="1" class="icon">
          <a v-if="selectedDonneeId!==null" class="icon" title="supprimer la donnée sélectionnée" @click = "deleteDonnee"
            align-self="center"><i class="fa-solid fa-trash-can" id="deleteDonnee"></i></a>
          <a v-else class="icon" title="supprimer la donnée sélectionnée" @click = "selection"
            align-self="center"><i class="fa-solid fa-trash-can" id="deleteDonnee"></i></a>
        </b-col>
        <b-col cols="3" class="icon">
          <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filtre_donnees" type="search" placeholder="Rechercher Donnée"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filtre_donnees" @click="filtre_donnees = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <div class="pagination">
            <p>{{nbResult}} donnée(s) sur {{totalRows}}</p>
          </div>
          <div class="pagination">
            <b-pagination class="customPagination"
                        v-model="currentPage"
                        :total-rows="nbResult !== 0 ? nbResult : filteredDonnees.length"
                        :per-page="perPage"
                        aria-controls="my-card"
                        size="sm"
                        align="right"
                        id="paginationDonnee">
            </b-pagination>
          </div>
        </b-col>
      </b-row>
      <div id="my-modal15" size="xl" v-if="showModalAdd">
        <ModalAdd @close="showModalAdd = false" @notification="handleNotification"></ModalAdd>
      </div>
      <div id="my-modal16" size="xl" v-if="showModalModif && lastSelectedDonneeId !== undefined">
        <ModalModif :id="lastSelectedDonneeId" @close="showModalModif = false" @notification="handleNotification"></ModalModif>
      </div>
    </div>
    <div v-if="donneesEtape.length!==0" class="donneesEtape mt-3">
      <b-card class="card-list">
        <p style="color: white"><strong><u>Données sélectionnées pour l'étape de traitement: {{etape.noEtape }} {{etape.nom}}</u></strong></p>
        <DonneesEtape :donneesEtape="donneesEtape"
        @deleteDonnee="handleDeleteDonnee">
        </DonneesEtape>
      </b-card>
      <div v-if="showDonnees" class="bouton">
        <b-button size="sm" v-on:click = "insertDonneesFromEtape(donneesEtape)" class="btn-ajout" id="addDonneeEtape">Enregistrer la séléction</b-button>
        <b-button size="sm" class="btn-ajout" @click="clearTable">Réinitialiser</b-button>
        <b-button size="sm" class="btn-ajout" @click="showModal" id="showRef">Passer directement aux références bibliographiques</b-button>
      </div>
    </div>
    <div class="bouton" v-else>
      <b-button v-if="showDonnees" size="sm" @click="showModal" class="btn-ajout" id="showRef">Passer directement aux références bibliographiques</b-button>
    </div>

    <div v-if="showModalRefs" class="ref mt-3" id="refMethode">
      <ModalRef
        :refsBiblios="refsBiblios"
        :idEtape="idEtape"
        :showModal="showModal">
      </ModalRef>
    </div>
  </div>
</template>
<script>
import useTypeDonneesList from "@/composition/Donnees/typeDonneesList";
import DonneesEtape from '@/components/Methode/Etape/Donnees/TypesDonneesAppList.vue';
import ModalAdd from "@/components/Methode/Etape/Donnees/ModalAjouterTypesDonnees.vue";
import ModalModif from "@/components/Methode/Etape/Donnees/ModalModifierTypesDonnees.vue";
import ModalRef from "@/components/Methode/Etape/Ref/RefBiblioForMethodeList.vue";
import NotificationMessage from "@/components/Notification.vue";
import {ref} from "vue";
import eventBus from "@/eventBus";

export default {
  name: "DonneesList",
  components: {NotificationMessage, ModalRef, DonneesEtape, ModalAdd, ModalModif },
  props: {
    donnees: {
      type: Array,
      required: true,
    },
    idEtape: {
      type: Number,
      required: true,
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props) {
    const donneesList = useTypeDonneesList(props);
    const showNotification = ref(false);
    const notificationMessage = ref("");
    const notificationType = ref("info");

    const selection = () => {
      notificationMessage.value = "Veuillez sélectionner une donnée";
      notificationType.value = "info";
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };

    const handleNotification = ({ message, messageType }) => {
      notificationMessage.value = message;
      notificationType.value = messageType;
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };
    eventBus.on("notification", handleNotification);

    return {
      ...donneesList,
      showNotification,
      notificationMessage,
      notificationType,
      selection,
      handleNotification
    };
  },
};
</script>
<style scoped>

.donneesEtape{
  text-align: center;
  justify-content: center;
}
</style>