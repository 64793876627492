import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import http from '@/http.js';
import eventBus from '@/eventBus.js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useAjoutDonnees(emit) {
    const locale = ref("fr-FR");
    const idDonnee = ref("");
    const type = ref("");
    const sousType = ref("");
    const source = ref("");
    const echelleGeo = ref("");
    const echelleTemp = ref("");
    const typeValeur = ref("");
    const donneesData = ref({});
    const donneeSol = ref(false);
    const options = ref([
        { value: 'Quantitative', text: 'Quantitative' },
        { value: 'Qualitative', text: 'Qualitative' }
    ]);

    const message = ref("");
    const showNotification = ref(false);
    const messageType = ref("info");
    const duration = ref(5000);

    const schema = yup.object({
        type: yup.string().required('Ce champ est obligatoire'),
    });

    const { handleSubmit } = useForm({
        validationSchema: schema
    });

    onMounted(() => {
        const handleInsertDonnee = handleSubmit(async (values) => {
            donneesData.value = {
                idDonnee: idDonnee.value,
                type: values.type,
                sousType: sousType.value,
                source: source.value,
                echelleGeo: echelleGeo.value,
                echelleTemp: echelleTemp.value,
                typeValeur: typeValeur.value,
                donneeSol: donneeSol.value,
            };
            try {
                const data = await http.insertDonnees(donneesData.value);
                idDonnee.value = data;
                emit('close');
                eventBus.emit('reloadDonnees');
                emit('notification',{
                    showNotification : true,
                    message : "Donnée ajoutée avec succès!",
                    messageType : "success",
                })
            } catch (error) {
                emit('notification',{
                    showNotification : true,
                    message : 'Erreur lors de l\'ajout de la donnée.',
                    messageType : "danger",
                })
            }
        });

        eventBus.on('insertDonnees', handleInsertDonnee);

        onBeforeUnmount(() => {
            eventBus.off('insertDonnees', handleInsertDonnee);
        });
    });

    const emitInsertDonnees = () => {
        eventBus.emit("insertDonnees");
    };

    return {
        locale,
        idDonnee,
        type,
        sousType,
        source,
        echelleGeo,
        echelleTemp,
        typeValeur,
        options,
        emitInsertDonnees,
        donneesData,
        donneeSol,
        message,
        showNotification,
        messageType,
        duration
    };
}