import { ref, computed, onMounted, watch, watchEffect } from "vue";
import http from "@/http";
import eventBus from "@/eventBus";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useRefsBiblioMethode(props) {
    const refBiblio = ref({});
    const refsMethode = ref([]);
    const selectedRefBiblios = ref([]);
    const selectedRefBiblioId = ref(null);
    const totalRows = computed(() => props.refsBiblios.length);
    const perPage = ref(10);
    const currentPage = ref(1);
    const selectMode = ref('multi');
    const showModalAdd = ref(false);
    const showModalModif = ref(false);
    const idRef = ref(null);
    const filtre_refBiblios = ref("");
    const nbResult = ref(0);
    const localRefs = ref([]);
    const fields = ref([
        { key: 'selection', label: 'Sélection', class: 'text-center' },
        { key: 'idRef', label: 'Id', class: 'text-center' },
        { key: 'titre', label: 'Titre', class: 'text-center' },
        { key: 'type', label: 'Type réf', class: 'text-center' },
        { key: 'document', label: 'Doc. source', class: 'text-center' },
        { key: 'annee', label: 'Année publi.', class: 'text-center' }
    ]);
    const showRefs = ref(true);
    const etape = ref({});
    const idEtp = ref(props.idEtape);
    const lastSelectedRefBiblioId = computed(() => {
        if (selectedRefBiblios.value.length > 0) {
            return selectedRefBiblios.value[selectedRefBiblios.value.length - 1];
        }
        return null;
    });

    const filteredRefBiblios = computed(() => {
        if (!filtre_refBiblios.value) return props.refsBiblios;
        return props.refsBiblios.filter(
            (ref) =>
                (ref.titre && ref.titre.toLowerCase().includes(filtre_refBiblios.value.toLowerCase())) ||
                (ref.document && ref.document.toLowerCase().includes(filtre_refBiblios.value.toLowerCase())) ||
                (ref.annee && ref.annee.toString().includes(filtre_refBiblios.value))
        );
    });

    watch(lastSelectedRefBiblioId, (newVal) => {
        if (newVal !== null) {
            eventBus.emit("updateRefBiblioSelected", newVal);
        }
    });

    watchEffect(() => {
        nbResult.value = filteredRefBiblios.value.length;
        currentPage.value = 1;
    });

    const updateRefs = () => {
        if (!filtre_refBiblios.value) {
            nbResult.value = props.refsBiblios.length;
            return;
        }
        nbResult.value = filteredRefBiblios.value.length;
    };

    const handleDeleteRef = (ref) => {
        refsMethode.value = refsMethode.value.filter(r => r.idRef !== ref.idRef);
        clearSelected();
    };

    const getRefsEtape = async () => {
        const data = await http.getRefsFromEtape(props.idEtape);
        refsMethode.value = data;
    };

    const loadEtape = async () => {
        const data = await http.loadEtape(props.idEtape);
        etape.value = data;
    };

    const toggleSelected = (ref) => {
        const index = selectedRefBiblios.value.indexOf(ref.idRef);
        if (index === -1) {
            selectedRefBiblios.value.push(ref.idRef);
            if (!refsMethode.value.some(r => r.idRef === ref.idRef)) {
                refsMethode.value.push(ref);
            }
        } else {
            selectedRefBiblios.value.splice(index, 1);
            refsMethode.value = refsMethode.value.filter(r => r.idRef !== ref.idRef);
        }
        selectedRefBiblioId.value = ref.idRef;
    };

    const isSelected = (refId) => {
        return selectedRefBiblios.value.includes(refId);
    };

    const clearTable = () => {
        refsMethode.value = [];
        clearSelected();
    };

    const clearSelected = () => {
        selectedRefBiblios.value = [];
    };

    const insertRefFromEtape = async (refsMethode) => {
        if (idEtp.value !== undefined && idEtp.value !== null) {
            await http.deleteRefsFromEtape(idEtp.value);
            await Promise.all(refsMethode.map(ref => http.insertRefsFromEtape(props.idEtape, ref.idRef)));
            showRefs.value = false;
            eventBus.emit("notification", {
                message: "Références bobliographique ajoutée(s) à l'étape avec succès",
                type: "success"
            });
        }else{
            eventBus.emit("notification", {
                message: "Veuillez saisir une étape",
                type: "info"
            });
        }
    };

    const deleteRef = async () => {
        if (selectedRefBiblioId.value !== null) {
            const exists = await http.verifLMethTraitRefExist(selectedRefBiblioId.value);
            if (exists) {
                eventBus.emit("notification", {
                    message: `Impossible de supprimer la référence ${selectedRefBiblioId.value} car elle est associée à des methodes.`,
                    messageType: "danger"
                });
            } else {
                if (confirm(`Voulez vous vraiment supprimer la référence : ${selectedRefBiblioId.value}`)) {
                    try{
                        await http.deleteRef(selectedRefBiblioId.value);
                        eventBus.emit("notification", {
                            message: "La référence a été supprimée avec succès",
                            type: "success"
                        });
                        refsMethode.value = refsMethode.value.filter(r => r.idRef !== selectedRefBiblioId.value);
                        eventBus.emit("reloadRefs");
                    } catch (error) {
                        eventBus.emit("notification", {
                            message: "Erreur lors de la suppression de la référence",
                            type: "danger"
                        });
                    }
                }
            }
        }
    };

    onMounted(() => {
        getRefsEtape();
        if (props.idEtape !== 0) {
            loadEtape();
        }
    });

    const paginatedRefBiblios = computed(() => {
        const start = (currentPage.value - 1) * perPage.value;
        const end = start + perPage.value;
        return filteredRefBiblios.value.slice(start, end);
    });

    return {
        refBiblio,
        selectedRefBiblioId,
        selectedRefBiblios,
        refsMethode,
        perPage,
        currentPage,
        selectMode,
        showModalAdd,
        showModalModif,
        idRef,
        filtre_refBiblios,
        filteredRefBiblios,
        nbResult,
        localRefs,
        fields,
        showRefs,
        etape,
        idEtp,
        updateRefs,
        handleDeleteRef,
        loadEtape,
        getRefsEtape,
        clearTable,
        clearSelected,
        insertRefFromEtape,
        deleteRef,
        paginatedRefBiblios,
        isSelected,
        totalRows,
        toggleSelected,
        lastSelectedRefBiblioId,
    };
}