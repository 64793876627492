<template>
  <div class="refBiblioList">
    <div>
      <NotificationMessage v-if="showNotification" :message="notificationMessage" :type="notificationType" :duration="5000"/>
    </div>
    <div v-if="showRefs">
      <p style="padding-top: 10px"><strong>III. Références bibliographiques de la méthode de traitement</strong></p>
      <div class="tableElement">
      <table id="tableRef" class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ref in paginatedRefBiblios" :key="ref.idRef" @click="toggleSelected(ref)"
              :class="{ 'table-active': isSelected(ref.idRef) }">
            <td class="text-center">
              <input type="checkbox" v-model="selectedRefBiblios" :value="ref.idRef" disabled>
            </td>
            <td class="text-center">{{ ref.idRef }}</td>
            <td class="text-center">{{ ref.titre }}</td>
            <td class="text-center">{{ ref.type }}</td>
            <td class="text-center">{{ ref.document }}</td>
            <td class="text-center">{{ ref.annee }}</td>
          </tr>
        </tbody>
      </table>
      </div>
      <b-row>
        <b-col cols="1" class="icon">
          <a class="icon" title="ajouter une nouvelle référence"
          @click.prevent ="showModalAdd = !showModalAdd; showModalModif=false" align-self="center"><i class="fas fa-plus-circle" id="ajoutRef"></i></a>
        </b-col>
        <b-col cols="1" class="icon">
          <a class="icon" v-if="selectedRefBiblioId!==null" title="modifier la référence sélectionnée"
             @click.prevent ="showModalModif = !showModalModif; showModalAdd=false" align-self="center"><i class="fa-solid fa-pen-to-square" id="modifRef"></i></a>
          <a class="icon" v-else title="modifier la référence sélectionnée" @click="selection"
             align-self="center"><i class="fa-solid fa-pen-to-square" id="modifRef"></i></a>
        </b-col>
        <b-col cols="1" class="icon">
          <a v-if="selectedRefBiblioId!==null" class="icon" title="supprimer la référence sélectionnée"
             v-on:click = "deleteRef" align-self="center"><i class="fa-solid fa-trash-can" id="deleteRef"></i></a>
          <a v-else class="icon" title="supprimer la référence sélectionnée" @click = "selection"
             align-self="center"><i class="fa-solid fa-trash-can" id="deleteRef"></i></a>
        </b-col>
        <b-col cols="3" class="icon">
          <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filtre_refBiblios" type="search" placeholder="Rechercher Référence"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filtre_refBiblios" @click="filtre_refBiblios = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <div class="pagination">
            <p>{{nbResult}} référence(s) sur {{totalRows}}</p>
          </div>
          <div class="pagination">
            <b-pagination class="customPagination"
                          v-model="currentPage"
                          :total-rows="nbResult !== 0 ? nbResult : filteredRefBiblios.length"
                          :per-page="perPage"
                          aria-controls="my-card"
                          size="sm"
                          align="right"
                          id="paginationRef">
            </b-pagination>
          </div>
        </b-col>
      </b-row>

      <div id="my-modal17" size="xl" v-if="showModalAdd">
        <ModalAdd
            @close="showModalAdd = false" @notification="handleNotification">
        </ModalAdd>
      </div>
      <div id="my-modal18" size="xl" v-if="showModalModif && lastSelectedRefBiblioId !== undefined" @notification="handleNotification">
        <ModalModif
            :id="lastSelectedRefBiblioId"
            @close="showModalModif = false">
        </ModalModif>
      </div>
    </div>
    <div v-if="refsMethode.length!==0" class="refsMethode mt-3">
      <b-card class="card-list">
        <p style="color: white"><strong><u>Liste des Références de l'étape séléctionnée: {{etape.noEtape}} {{etape.nom}}</u></strong></p>
        <RefsMethode
            :refsMethode="refsMethode"
            @deleteRef="handleDeleteRef">
        </RefsMethode>
      </b-card>
      <div v-if="showRefs" class="bouton">
        <b-button size="sm" v-on:click="insertRefFromEtape(refsMethode)" class="btn-ajout" id="addRefFromEtape">Enregistrer la séléction</b-button>
        <b-button size="sm" class="btn-ajout" @click="clearTable">Réinitialiser</b-button>
        <b-button size="sm" @click="showModal" class="btn-ajout">revenir aux données</b-button>
      </div>
    </div>
  </div>
</template>
<script>

import ModalAdd from "@/components/Applications/RefBiblio/ModalAjouterRef.vue";
import ModalModif from "@/components/Applications/RefBiblio/ModalModifierRef.vue";
import RefsMethode from "@/components/Methode/Etape/Ref/RefBiblioMethodeList.vue";
import useRefsBiblioMethode from "@/composition/RefBiblio/refBiblioMethodeList";
import NotificationMessage from "@/components/Notification.vue";
import {ref} from "vue";
import eventBus from "@/eventBus";

export default {
  name: "ReferenceBiblio",
  components: {NotificationMessage, ModalModif, ModalAdd, RefsMethode},

  props:{
    refsBiblios:{
      type:Array,
      required:true
    },
    idEtape:{
      type:Number,
      required: true
    },
    showModal:Function
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props) {
    const refsBiblioMethodeList = useRefsBiblioMethode(props);
    const showNotification = ref(false);
    const notificationMessage = ref("");
    const notificationType = ref("info");

    const selection = () => {
      notificationMessage.value = "Veuillez sélectionner une référence";
      notificationType.value = "info";
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };

    const handleNotification = ({ message, type }) => {
      notificationMessage.value = message;
      notificationType.value = type;
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };
    eventBus.on("notification", handleNotification);

    return {
      ...refsBiblioMethodeList,
      showNotification,
      notificationMessage,
      notificationType,
      selection,
      handleNotification,
    };
  },
};
</script>
<style scoped>

.refsMethode{
  text-align: center;
  justify-content: center;
}

</style>