import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import http from '@/http';
import eventBus from '@/eventBus';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useModifEtape({ props, emit }) {
    const locale = ref("fr-FR");
    const idEtape = ref(0);
    const identifiantEtape = ref(props.id);
    const noEtape = ref("");
    const nom = ref("");
    const typeMethTraits = ref([]);
    const typeMethTrait = ref({});
    const descriptionMethTrait = ref("");
    const donnees = ref([]);
    const show = ref(true);
    const showModalDonnees = ref(false);

    const message = ref("");
    const showNotification = ref(false);
    const type = ref("info");
    const duration = ref(5000);

    const schema = yup.object({
        noEtape: yup.string().required('Ce champ est obligatoire'),
        nom: yup.string().required('Ce champ est obligatoire')
    });

    const { handleSubmit } = useForm({
        validationSchema: schema
    });

    const getDonnees = () => {
        http.getDonnees().then((data) => {
            donnees.value = data;
        });
    };

    const fetchTypeMethTraits = async () => {
        typeMethTraits.value = await http.getTypeMethTrait();
    };

    const emitModifierEtape = handleSubmit(async (values) => {
        try {
            const etapeData = {
                idEtape: idEtape.value,
                noEtape: values.noEtape,
                nom: values.nom,
                typeMethTrait: typeMethTrait.value,
                descriptionMethTrait: descriptionMethTrait.value
            };
            const data = await http.updateEtape(etapeData);
            identifiantEtape.value = data;
            emit('close');
            eventBus.emit("reloadEtapesMeth", props.idApp);
            emit('notification',{
                showNotification : true,
                message : "Etape modifiée avec succès!",
                type : "success",
            })
        } catch (error) {
            emit('notification',{
                showNotification : true,
                message : 'Erreur lors de la modification de l\'etape.',
                type : "danger",
            })
        }
    });

    onMounted(() => {
        fetchTypeMethTraits();
        getDonnees();

        if (props.id !== undefined) {
            http.loadEtape(props.id).then((data) => {
                idEtape.value = data.idEtape;
                noEtape.value = data.noEtape;
                nom.value = data.nom;
                if (data.typeMethTrait !== null && data.typeMethTrait !== undefined) {
                    typeMethTrait.value = data.typeMethTrait;
                }
                descriptionMethTrait.value = data.descriptionMethTrait;
            });
        }

        eventBus.on("reloadDonnees", getDonnees);
        eventBus.on('modifierEtape', emitModifierEtape);
    });

    onBeforeUnmount(() => {
        eventBus.off("reloadDonnees", getDonnees);
        eventBus.off('modifierEtape', emitModifierEtape);
    });

    return {
        locale,
        idEtape,
        noEtape,
        nom,
        typeMethTraits,
        typeMethTrait,
        descriptionMethTrait,
        donnees,
        show,
        showModalDonnees,
        emitModifierEtape,
        message,
        showNotification,
        type,
        duration
    };
}