<template>
  <div class="refList">
    <div class="tableSecondElement">
      <table class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ref in refsMethode" :key="ref.idDonnee">
            <td class="text-center">{{ ref.idRef }}</td>
            <td class="text-center">{{ ref.titre }}</td>
            <td class="text-center">{{ ref.type }}</td>
            <td class="text-center">{{ ref.document }}</td>
            <td class="text-center">{{ ref.annee }}</td>
            <td class="text-center"><a @click="supprimerRef(ref)" class="icon_delete" title="supprimer l'organisme"><i class="fa-solid fa-trash-can"></i></a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import { defineComponent, ref, toRefs } from 'vue';
export default defineComponent({
  name: "RefBibliosList",

  props:{
    refsMethode:{
      type:Array,
      required:true
    },

    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  setup(props, { emit }) {
    const fields = ref([
      {key: 'idRef', label:'Id', class: 'text-center'},
      {key: 'titre', label:'Titre', class: 'text-center'},
      {key: 'type', label:'Type réf', class: 'text-center'},
      {key: 'document', label:'Doc. source', class: 'text-center'},
      {key: 'annee', label:'Année publi.', class: 'text-center'},
      { key: "actions", label: "Actions", class: "text-center" }
    ]);

    const supprimerRef = (ref) => {
      emit("deleteRef", ref);
    };

    return {
      ...toRefs(props),
      fields,
      supprimerRef
    };
  }
})
</script>
<style scoped>


</style>