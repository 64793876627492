<template>
  <transition name="modal">
    <b-card class="modal-container">
        <form @submit.prevent="emitModifierDonnees" id="modifDonnee">
          <div class="titreModal pt-2 pb-2">
            <h5>Modifier le type de données sélectionné</h5>
          </div>
          <div>
            <b-card class=" formModal mr-4 ml-4">
              <b-form-group class="labelTitre" id="input-group-1" label="Type de données*:" label-size="sm" label-for="input-horizontal">
                <Field name="type" type="text" v-model="type" class="form-control" />
                <ErrorMessage name="type" class="text-danger" />
              </b-form-group>
              <b-form-group class="labelTitre" id="input-group-2" label="Sous Type:" label-size="sm" label-for="input-2">
                <b-form-input id="input-2" v-model="sousType" type="text" size="sm">
                </b-form-input>
              </b-form-group>
              <b-form-group class="labelTitre" id="input-group-3" label="Source données:" label-size="sm" label-for="input-3">
                <b-form-input id="input-3" v-model="source" type="text" size="sm">
                </b-form-input>
              </b-form-group>
              <b-form-group class="labelTitre" id="input-group-4" label="Echelle géographique:" label-size="sm" label-for="input-4">
                <b-form-input id="input-4" v-model="echelleGeo" type="text" size="sm">
                </b-form-input>
              </b-form-group>
              <b-form-group class="labelTitre" id="input-group-5" label="Echelle temporelle:" label-size="sm" label-for="input-5">
                <b-form-input id="input-5" v-model="echelleTemp" type="text" size="sm">
                </b-form-input>
              </b-form-group>
              <b-form-group class="labelTitre"  label="Données sol:" label-size="sm" label-for="checkbox-2">
                <b-form-checkbox id="checkbox-2" v-model="donneeSol">
                </b-form-checkbox>
              </b-form-group>
              <b-form-group class="labelTitre" id="input-group-6" label="Type valeur:" label-size="sm" label-for="input-6">
                <b-form-select id="input-6" size="sm" v-model="typeValeur" :options="options">
                </b-form-select>
              </b-form-group>
            </b-card>
          </div>
          <div class="bouton">
            <b-button pill type="submit" class="valid m-2" id="validModifDonnee">Valider</b-button>
            <b-button pill class="closed m-2" @click="$emit('close')">Annuler</b-button>
          </div>
        </form>
    </b-card>
  </transition>
</template>

<script>

import { Field, ErrorMessage} from 'vee-validate';
import useModifDonnees from "@/composition/Donnees/modifDonnees";

export default {
  name:"ModalModifierDonnees",
  components: {
    Field,
    ErrorMessage
  },

  props:{
    id:{
      type:Number
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props, { emit }) {
    return useModifDonnees(props, emit);
  }
};
</script>

<style>

</style>