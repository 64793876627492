<template>
  <div class="applicationList">
    <div>
      <NotificationMessage v-if="showNotification" :message="notificationMessage" :type="notificationType" :duration="5000"/>
    </div>
    <div class="tableElement">
      <table id="tableAppMethode" class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="application in paginatedApplications" :key="application.idApplication" @click="selectApplication(application.idApplication)"
              :class="{ 'table-active': application.idApplication === selectedApplicationId }">
            <td class="text-center">
              <input type="checkbox" :checked="application.idApplication === selectedApplicationId" disabled>
            </td>
            <td class="text-center">{{ application.idApplication }}</td>
            <td class="text-center">{{ application.titre }}</td>
            <td class="text-center">{{ application.annee }}</td>
            <td class="text-center">{{application.idMethode}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-row>
      <b-col cols="1" class="icon">
        <a class="icon" v-if="selectedApplicationId !== null" title="voir l'application sélectionnée"
          @click.prevent ="showModalAfficher =!showModalAfficher" align-self="center"><i class="fa-solid fa-eye" id="showApp"></i></a>
        <a class="icon" v-else title="voir l'application sélectionnée" @click="selection" align-self="center"><i class="fa-solid fa-eye" id="showApp"></i></a>
      </b-col>
      <b-col cols="3" class="icon">
        <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
          <b-input-group size="sm">
            <b-form-input id="filter-input" v-model="filtre_applications" type="search" placeholder="Rechercher Application"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filtre_applications" @click="filtre_applications = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <div class="pagination">
          <p>{{nbResult}} application(s) sur {{totalRows}}</p>
        </div>
        <div class="pagination">
          <b-pagination class="customPagination"
            v-model="currentPage"
            :total-rows="nbResult !== 0 ? nbResult : filteredApplications.length"
            :per-page="perPage"
            aria-controls="my-card"
            size="sm"
            align="right"
            id="paginationAppMethode">
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <div id="my-modal12" class="mb-4" v-if="showModalAfficher">
      <form>
        <ModalAfficher :id="selectedApplicationId" :lectureSeule="lectureSeule" v-if="showModalAfficher" @close="showModalAfficher = false" class="center"></ModalAfficher>
      </form>
    </div>

    <b-row>
        <b-button id="methode" class="app btn-block" @click.prevent ="showModalMethode = !showModalMethode">
            Méthode générale de mise en oeuvre de l'application
        </b-button>
      <div id="my-modal13" class="container-fluid">
        <ModalMethode :idApplicationMeth="selectedApplicationId" v-if="showModalMethode" @close="showModalMethode = false" @notification="handleNotification"></ModalMethode>
      </div>
    </b-row>
    <b-row>
      <b-button id="etape" class="app btn-block" @click.prevent ="showModalEtapes = !showModalEtapes; showModalMethode=false ">
        Renseignement des étapes de traitement de la méthode
      </b-button>
      <div id="my-modal14" class="container-fluid" v-if="showModalEtapes" @close="showModalEtapes = false">
        <div v-if="etapesMeth.length!==0" class="etapesApp mt-3">
          <b-card class="card-list">
            <p style="color: white"><strong><u>Ensemble des étapes sélectionnées pour la méthode générale de mise en oeuvre de l'application :</u></strong></p>
            <EtapesMeth
                :etapesMeth ="etapesMeth"
                :idApp = "selectedApplicationId">
            </EtapesMeth>
            <div class="bouton">
              <b-button pill  @click.prevent ="showModalCaractEtape = !showModalCaractEtape" class="valid m-2">Ajouter une nouvelle étape</b-button>
            </div>
          </b-card>
        </div>
        <div v-else @click.prevent ="showModalCaractEtape = !showModalCaractEtape " id="addEtapeFromApp" class="bouton"><b-button pill class="valid m-2">Ajouter une étape</b-button></div>
        <div id="my-modal19" v-if="showModalCaractEtape" class="etape mt-3">
          <ModalEtape
              :idMethodeEtape="idMethode"
              :etapesMeth ="etapesMeth"
              :idApp = "selectedApplicationId"
              @close="showModalCaractEtape = false" @notification="handleNotification">
          </ModalEtape>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import useApplicationMethodeList from "@/composition/Applications/applicationsMethodeList";
import ModalMethode from "@/components/Methode/Methode/MethodeGeneraleForm.vue";
import ModalEtape from "@/components/Methode/Etape/ModalAjouterEtape.vue";
import ModalAfficher from "@/components/Methode/Application/ModalAfficherAppMethode.vue";
import EtapesMeth from "@/components/Methode/Etape/ListEtapesByMethode.vue";
import NotificationMessage from "@/components/Notification.vue";
import {ref} from "vue";
import eventBus from "@/eventBus";

export default {
  name: "ApplicationsMethodeList",
  components: {NotificationMessage, ModalAfficher, ModalMethode, EtapesMeth, ModalEtape},

  props:{
    applications:{
      type:Array,
      required:true
    },
    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props){
    const applicationsMethodeList = useApplicationMethodeList(props);
    const showNotification = ref(false);
    const notificationMessage = ref("");
    const notificationType = ref("info");

    const selection = () => {
      notificationMessage.value = "Veuillez sélectionner une application";
      notificationType.value = "info";
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };

    const handleNotification = ({ message, type }) => {
      notificationMessage.value = message;
      notificationType.value = type;
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };
    eventBus.on("notification", handleNotification);

    return {
      ...applicationsMethodeList,
      showNotification,
      notificationMessage,
      notificationType,
      selection,
      handleNotification
    };
  },
}
</script>
<style scoped>

.app{
  background-color: #66c1bf !important;
  justify-content: center !important;
  color: black !important;
}

.etapesApp{
  text-align: center;
  justify-content: center;
}

</style>