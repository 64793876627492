import { ref, onMounted, onBeforeUnmount } from 'vue';
import http from "@/http";
import eventBus from '@/eventBus.js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useVoirApplicationMethode(id) {
    const titre = ref('');
    const annee = ref('');
    const idApplication = ref('');

    const updateModalData = (idApp) => {
        http.loadApplication(idApp).then((data) => {
            titre.value = data.titre;
            annee.value = data.annee;
            idApplication.value = data.idApplication;
        });
    };

    onMounted(() => {
        eventBus.on('updateApplicationSelected', updateModalData);

        if (id !== undefined) {
            http.loadApplication(id).then((data) => {
                titre.value = data.titre;
                annee.value = data.annee;
                idApplication.value = data.idApplication;
            });
        }
    });

    onBeforeUnmount(() => {
        eventBus.off('updateApplicationSelected', updateModalData);
    });

    return {
        titre,
        annee,
       idApplication,
    };
}
