import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import http from "@/http";
import eventBus from '@/eventBus.js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useFormMethode(props, emit) {
    const locale = ref("fr-FR");
    const idMethode = ref(0);
    const combineMethTrait = ref(false);
    const spatialisationMethode = ref(false);
    const facilite = ref({});
    const resolution = ref({});
    const validation = ref({});
    const outils = ref([]);
    const outilsToAdd = ref([]);
    const libelleMethode = ref('');
    const methodeData = ref({});
    const application = ref({});
    const facilites = ref([]);
    const resolutions = ref([]);
    const validations = ref([]);

    const message = ref("");
    const showNotification = ref(false);
    const type = ref("info");
    const duration = ref(5000);

    const schema = yup.object({
        libelleMethode: yup.string().required('Ce champ est obligatoire'),
    });

    const { handleSubmit } = useForm({
        validationSchema: schema
    });

    const getMethode = async (idApplicationMeth) => {
        if (idApplicationMeth !== null) {
            const appData = await http.loadApplication(idApplicationMeth);
            application.value = appData;
            if (application.value.idMethode !== null) {
                const methodData = await http.loadMethode(idApplicationMeth);
                idMethode.value = methodData.idMethode;
                combineMethTrait.value = methodData.combineMethTrait;
                spatialisationMethode.value = methodData.spatialisationMethode;
                facilite.value = methodData.faciliteMethode || {};
                resolution.value = methodData.resolutionMethode || {};
                validation.value = methodData.validationMethode || {};
                libelleMethode.value = methodData.libelleMethode || '';
                outils.value = methodData.outilsMethode;
                outilsToAdd.value = [...methodData.outilsMethode];
            }
        }
    };

    const reset = async () => {
        outils.value = await http.getOutils();
    };

    const emitInsertMethode = async () => {
        if (idMethode.value === 0) {
            if (props.idApplicationMeth !== null) {
                eventBus.emit("insertMethode");
            }
        } else {
            eventBus.emit("modifierMethode");
        }
    };

    const handleInsertmethode = handleSubmit(async (values) => {
        if (outilsToAdd.value.length === 0) {
            outilsToAdd.value = [...outils.value];
        }

        methodeData.value = {
            idMethode: idMethode.value,
            libelleMethode: values.libelleMethode,
            combineMethTrait: combineMethTrait.value,
            spatialisationMethode: spatialisationMethode.value,
            faciliteMethode: Array.isArray(facilite.value) ? facilite.value[0] : facilite.value,
            resolutionMethode: Array.isArray(resolution.value) ? resolution.value[0] : resolution.value,
            validationMethode: Array.isArray(validation.value) ? validation.value[0] : validation.value,
            outilsMethode: outilsToAdd.value,
            idApplication: props.idApplicationMeth,
        };
        try {
            const data = await http.insertMethode(methodeData.value);
            idMethode.value = data;
            emit('close');
            eventBus.emit('reloadMethode');
            emit('notification',{
                showNotification : true,
                message : "Méthode ajoutée avec succès!",
                type : "success",
            })
        } catch (error) {
            emit('notification',{
                showNotification : true,
                message : 'Erreur lors de l\'ajout de la methode.',
                type : "danger",
            })
        }
    });

    const handleModifierMethode = handleSubmit(async (values) => {
        if (outilsToAdd.value.length === 0) {
            outilsToAdd.value = [...outils.value];
        }

        methodeData.value = {
            idMethode: idMethode.value,
            libelleMethode: values.libelleMethode,
            combineMethTrait: combineMethTrait.value,
            spatialisationMethode: spatialisationMethode.value,
            faciliteMethode: Array.isArray(facilite.value) ? facilite.value[0] : facilite.value,
            resolutionMethode: Array.isArray(resolution.value) ? resolution.value[0] : resolution.value,
            validationMethode: Array.isArray(validation.value) ? validation.value[0] : validation.value,
            outilsMethode: outilsToAdd.value,
            idApplication: props.idApplicationMeth,
        };
        try {
            const data = await http.updateMethode(methodeData.value);
            idMethode.value = data;
            emit('close');
            eventBus.emit('reloadMethode');
            emit('notification',{
                showNotification : true,
                message : "Méthode modifiée avec succès!",
                type : "success",
            })
        } catch (error) {
            emit('notification',{
                showNotification : true,
                message : 'Erreur lors de la modification de la methode.',
                type : "danger",
            })
        }
    });

    onMounted(async () => {
        validations.value = await http.getValidations();
        facilites.value = await http.getFacilites();
        resolutions.value = await http.getResolutionsMethode();
        outils.value = await http.getOutils();
        getMethode(props.idApplicationMeth);

        eventBus.on('updateApplicationMethSelected', getMethode);
        eventBus.on('insertMethode', handleInsertmethode);
        eventBus.on('modifierMethode', handleModifierMethode);
    });

    onBeforeUnmount(() => {
        eventBus.off('updateApplicationMethSelected', getMethode);
        eventBus.off('insertMethode', handleInsertmethode);
        eventBus.off('modifierMethode', handleModifierMethode);
    });

    watch(() => props.idApplicationMeth, (newVal) => {
        getMethode(newVal);
    });

    return {
        locale,
        idMethode,
        combineMethTrait,
        spatialisationMethode,
        facilite,
        resolution,
        validation,
        outils,
        outilsToAdd,
        libelleMethode,
        methodeData,
        application,
        facilites,
        resolutions,
        validations,
        getMethode,
        reset,
        emitInsertMethode,
        message,
        showNotification,
        type,
        duration
    };
}