import {ref, computed, onMounted, watch, watchEffect, onBeforeUnmount} from "vue";
import http from "@/http";
import eventBus from '@/eventBus.js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useTypeDonneesList(props) {
    const donnee = ref({});
    const donneesEtape = ref([]);
    const selectedDonnees = ref([]);
    const selectedDonneeId = ref(null);
    const totalRows = computed(() => props.donnees.length);
    const perPage = ref(10);
    const currentPage = ref(1);
    const selectMode = ref('multi');
    const showModalAdd = ref(false);
    const showModalModif = ref(false);
    const idDonnee = ref(null);
    const filtre_donnees = ref("");
    const nbResult = ref(0);
    const localDonnees = ref([]);
    const fields = ref([
        { key: 'selection', label: 'Sélection', class: 'text-center' },
        { key: 'idDonnee', label: 'Id', class: 'text-center' },
        { key: 'type', label: 'Type de donnée', class: 'text-center' },
        { key: 'sousType', label: 'Sous Type', class: 'text-center' },
        { key: 'echelleGeo', label: 'Echelle géographique', class: 'text-center' },
        { key: 'echelleTemp', label: 'Echelle temporelle', class: 'text-center' },
        { key: 'donneeSol', label: 'Donnée sol', class: 'text-center' }
    ]);
    const refsBiblios = ref([]);
    const refsBiblioData = ref({});
    const showModalRefs = ref(false);
    const showDonnees = ref(true);
    const etape = ref({});
    const idEtp = ref(props.idEtape);

    const lastSelectedDonneeId = computed(() => {
        if (selectedDonnees.value.length > 0) {
            return selectedDonnees.value[selectedDonnees.value.length - 1];
        }
        return null;
    });

    const filteredDonnees = computed(() => {
        if (!filtre_donnees.value) return props.donnees;

        return props.donnees.filter(
            (donnee) =>
                (donnee.type && donnee.type.toLowerCase().includes(filtre_donnees.value.toLowerCase())) ||
                (donnee.sousType && donnee.sousType.toLowerCase().includes(filtre_donnees.value.toLowerCase())) ||
                (donnee.echelleGeo && donnee.echelleGeo.toLowerCase().includes(filtre_donnees.value.toLowerCase())) ||
                (donnee.echelleTemp && donnee.echelleTemp.toLowerCase().includes(filtre_donnees.value.toLowerCase()))
        );
    });

    watch(lastSelectedDonneeId, (newVal) => {
        if (newVal !== null) {
            eventBus.emit("updateDonneeSelected", newVal);
        }
    });

    watchEffect(() => {
        nbResult.value = filteredDonnees.value.length;
        currentPage.value = 1;
    });

    const updateDonnees = () => {
        if (!filtre_donnees.value) {
            nbResult.value = props.donnees.length;
            return;
        }
        nbResult.value = filteredDonnees.value.length;
    };

    const handleDeleteDonnee = (donnee) => {
        donneesEtape.value = donneesEtape.value.filter(d => d.idDonnee !== donnee.idDonnee);
        clearSelected();
    };

    const getRefs = async () => {
        const data = await http.getRefs();
        refsBiblios.value = data;
    };

    const loadEtape = async () => {
        const data = await http.loadEtape(props.idEtape);
        etape.value = data;
    };

    const getDonneesEtape = async () => {
        const data = await http.getDonneesFromEtape(props.idEtape);
        donneesEtape.value = data;
    };

    const toggleSelected = (donnee) => {
        const index = selectedDonnees.value.indexOf(donnee.idDonnee);
        if (index === -1) {
            selectedDonnees.value.push(donnee.idDonnee);
            if (!donneesEtape.value.some(d => d.idDonnee === donnee.idDonnee)) {
                donneesEtape.value.push(donnee);
            }
        } else {
            selectedDonnees.value.splice(index, 1);
            donneesEtape.value = donneesEtape.value.filter(d => d.idDonnee !== donnee.idDonnee);
        }
        selectedDonneeId.value = donnee.idDonnee;
    };

    const isSelected = (donneeId) => {
        return selectedDonnees.value.includes(donneeId);
    };

    const clearTable = () => {
        donneesEtape.value = [];
        clearSelected();
    };

    const clearSelected = () => {
        selectedDonnees.value = [];
    };

    const showModal = () => {
        showDonnees.value = !showDonnees.value;
        showModalRefs.value = !showModalRefs.value;
    };

    const insertDonneesFromEtape = async (donneesEtape) => {
        if (idEtp.value !== undefined) {
            await http.deleteDonneesFromEtape(idEtp.value);
            await Promise.all(donneesEtape.map(donnee => http.insertDonneesFromEtape(props.idEtape, donnee.idDonnee)));
            showDonnees.value = false;
            showModalRefs.value = true;
            eventBus.emit("notification", {
                message: "Données ajoutée(s) à l'étape avec succès",
                type: "success"
            });
        }else{
            eventBus.emit("notification", {
                message: "Veuillez saisir une étape",
                type: "info"
            });
        }
    };

    const deleteDonnee = async () => {
        if (selectedDonneeId.value !== null) {
            const exists = await http.verifLEtapeDonneeExist(selectedDonneeId.value);
            if (exists) {
                eventBus.emit("notification", {
                    message: `Impossible de supprimer la donnée ${selectedDonneeId.value} car elle est associée à des applications.`,
                    messageType: "danger"
                });
            } else {
                if (confirm(`Voulez vous vraiment supprimer la donnee : ${selectedDonneeId.value}`)) {
                    try{
                        await http.deleteDonnee(selectedDonneeId.value);
                        eventBus.emit("notification", {
                            message: "La donnée a été supprimée avec succès",
                            messageType: "success"
                        });
                        donneesEtape.value = donneesEtape.value.filter(d => d.idDonnee !== selectedDonneeId.value);
                        eventBus.emit("reloadDonnees");
                    } catch (error) {
                        eventBus.emit("notification", {
                            message: "Erreur lors de la suppression de la donnée",
                            messageType: "danger"
                        });
                    }
                }
            }
        }
    };

    onMounted(() => {
        eventBus.on("reloadRefs", getRefs);
        getRefs();
        getDonneesEtape();
        if (props.idEtape !== 0) {
            loadEtape();
        }
        eventBus.on("donneeModifie", (donneeModifie) => {
            const index = donneesEtape.value.findIndex(d => d.idDonnee === donneeModifie.idDonnee);
            if (index !== -1) {
                donneesEtape.value[index] = donneeModifie;
            } else {
                donneesEtape.value.push(donneeModifie);
            }
        });
    });

    onBeforeUnmount(() => {
        eventBus.off("donneeModifie");
    });

    const paginatedDonnees = computed(() => {
        const start = (currentPage.value - 1) * perPage.value;
        const end = start + perPage.value;
        return filteredDonnees.value.slice(start, end);
    });

    return {
        donnee,
        selectedDonneeId,
        selectedDonnees,
        donneesEtape,
        perPage,
        currentPage,
        selectMode,
        showModalAdd,
        showModalModif,
        idDonnee,
        filtre_donnees,
        nbResult,
        localDonnees,
        fields,
        refsBiblios,
        refsBiblioData,
        showModalRefs,
        showDonnees,
        etape,
        idEtp,
        updateDonnees,
        handleDeleteDonnee,
        getRefs,
        loadEtape,
        getDonneesEtape,
        clearTable,
        clearSelected,
        showModal,
        insertDonneesFromEtape,
        deleteDonnee,
        paginatedDonnees,
        isSelected,
        totalRows,
        toggleSelected,
        lastSelectedDonneeId,
    };
}