<template>
  <div class="applications">
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="card p-1" role="tab">
          <b-button block @click="toggleAccordion" class="app">Sélection d'une application existante</b-button>
        </b-card-header>
          <b-card-body id="accordion-1" v-show="accordionOpen">
            <ApplicationMethodeList :applications="applicationsMethode"/>
          </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
import {useApplications} from "@/composition/Applications/applications";
import ApplicationMethodeList from "@/components/Methode/Application/ApplicationMethodeList.vue";

export default {
  name: "ApplicationsMethodeList",
  components: { ApplicationMethodeList},


  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const {
      applicationsMethode,
      applicationData,
      accordionOpen,
      toggleAccordion
    } = useApplications();

    return {
      applicationsMethode,
      applicationData,
      accordionOpen,
      toggleAccordion,
    };
  },
};
</script>
<style>
#accordion-1 {
  background-color: aliceblue;
}
.applications{
  margin-left: 4em;
  margin-right: 4em;
  background-color: aliceblue;
}
.app{
  background-color: #66c1bf !important;
}
</style>