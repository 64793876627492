import { ref, onMounted, onBeforeUnmount } from 'vue';
import http from '@/http.js';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import eventBus from '@/eventBus.js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useAjoutEtape({props, emit}) {
    const locale = ref("fr-FR");
    const idEtape = ref(0);
    const noEtape = ref("");
    const nom = ref("");
    const typeMethTraits = ref([]);
    const typeMethTrait = ref({});
    const descriptionMethTrait = ref("");
    const donnees = ref([]);
    const etapeToAdd = ref({});
    const show = ref(true);
    const showModalDonnees = ref(false);
    const showAddMethEtape = ref(true);
    const etapeData = ref({});

    const message = ref("");
    const showNotification = ref(false);
    const type = ref("info");
    const duration = ref(5000);

    const schema = yup.object({
        noEtape: yup.string().required('Ce champ est obligatoire'),
        nom: yup.string().required('Ce champ est obligatoire')
    });

    const { handleSubmit } = useForm({
        validationSchema: schema
    });

    const getDonnees = () => {
        http.getDonnees().then((data) => {
            donnees.value = data;
        });
    };

    const emitInsertEtape = () => {
        if (props.idMethodeEtape !== null) {
            eventBus.emit("insertEtape");
        } else{
            emit('notification',{
                showNotification : true,
                message : "Veuillez saisir une méthode",
                type : "danger",
            })
        }
    };

    const handleInsertEtape = handleSubmit(async (values) => {
        etapeData.value = {
            idEtape: idEtape.value,
            noEtape: values.noEtape,
            nom: values.nom,
            typeMethTrait: typeMethTrait.value,
            descriptionMethTrait: descriptionMethTrait.value
        };

        try {
            const data = await http.insertEtape(etapeData.value);
            idEtape.value = data;
            emit('notification',{
                showNotification : true,
                message : "Etape ajoutée avec succès!",
                type : "success",
            })
            if (idEtape.value !== undefined) {
                showModalDonnees.value = true;
                show.value = false;
            }
        } catch (error) {
            emit('notification',{
                showNotification : true,
                message : 'Erreur lors de l\'ajout de l\'etape.',
                type : "danger",
            })
        }
    });

    const insertEtapeFromMethode = () => {
        if (idEtape.value !== undefined) {
            etapeToAdd.value = {
                idMeth: props.idMethodeEtape,
                idEtape: idEtape.value
            };
            http.insertEtapeFromMethode(etapeToAdd.value).then(() => {
                show.value = false;
                showModalDonnees.value = false;
                showAddMethEtape.value = false;
                eventBus.emit("reloadEtapesMeth", props.idApp);
                emit('notification',{
                    showNotification : true,
                    message : "Etape ajoutée(s) à la méthode avec succès",
                    type : "success",
                })
            });
        }
    };

    const fetchTypeMethTraits = async () => {
        typeMethTraits.value = await http.getTypeMethTrait();
    };

    onMounted(() => {
        fetchTypeMethTraits();
        getDonnees();
        eventBus.on("reloadDonnees", getDonnees);
        eventBus.on('insertEtape', handleInsertEtape);
    });

    onBeforeUnmount(() => {
        eventBus.off("reloadDonnees", getDonnees);
        eventBus.off('insertEtape', handleInsertEtape);
    });

    return {
        locale,
        idEtape,
        etapeData,
        noEtape,
        nom,
        typeMethTraits,
        typeMethTrait,
        descriptionMethTrait,
        donnees,
        show,
        showModalDonnees,
        showAddMethEtape,
        emitInsertEtape,
        insertEtapeFromMethode,
        message,
        showNotification,
        type,
        duration
    };
}