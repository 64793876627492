<template>
  <div class="typeDonneesList">
    <div class="tableSecondElement">
      <table class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="donnee in donneesEtape" :key="donnee.idDonnee">
            <td class="text-center">{{ donnee.idDonnee }}</td>
            <td class="text-center">{{ donnee.type }}</td>
            <td class="text-center">{{ donnee.sousType }}</td>
            <td class="text-center">{{ donnee.echelleGeo }}</td>
            <td class="text-center">{{ donnee.echelleTemp }}</td>
            <td class="text-center">{{ donnee.donneeSol }}</td>
            <td class="text-center"><a @click="supprimerDonnees(donnee)" class="icon_delete" title="supprimer l'organisme"><i class="fa-solid fa-trash-can"></i></a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import { defineComponent, ref, toRefs } from 'vue';
export default defineComponent({
  name: "DonneesList",

  props:{
    donneesEtape:{
      type:Array,
      required:true
    },

    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  setup(props, { emit }) {
    const fields = ref([
      {key: 'idDonnee', label:'Id', class: 'text-center'},
      {key: 'type', label:'Type de donnée', class: 'text-center'},
      {key: 'sousType', label:'Sous Type', class: 'text-center'},
      {key: 'echelleGeo', label:'Echelle géographique', class: 'text-center'},
      {key: 'echelleTemp', label:'Echelle temporelle', class: 'text-center'},
      {key: 'donneeSol', label:'Donnée sol', class: 'text-center'},
      { key: "actions", label: "Actions", class: "text-center" }
    ]);

    const supprimerDonnees = (donnee) => {
      emit("deleteDonnee", donnee);
    };

    return {
      ...toRefs(props),
      fields,
      supprimerDonnees
    };
  }

});
</script>
<style scoped>

</style>