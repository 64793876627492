<template>
  <transition name="modal">
    <b-card class="modal-container">
      <div class="titreAfficher pt-2 pb-2">
        <h5>Caractéristiques générales de l'application </h5>
      </div>

      <div>
        <b-card class="formAfficher mr-4 ml-4">
          <form class="afficher">
            <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Id:" label-for="input-2">
              <b-form-input id="input-2" size="sm" v-model="idApplication" type="text" :readonly="lectureSeule">
              </b-form-input>
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-1" label-size="sm" label="Titre:" label-for="input-horizontal">
              <b-form-input id="input-horizontal" v-model="titre" size="sm" type="text" :readonly="lectureSeule">
              </b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Année:" label-for="input-2">
                  <b-form-input id="input-2" size="sm" v-model="annee" type="text" :readonly="lectureSeule">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </form>
        </b-card>
      </div>

    </b-card>
  </transition>
</template>

<script>

import useVoirApplicationMethode from "@/composition/Applications/voirApplicationMethode";

export default {
  name: 'AfficherApplication',

  props:{
    id:{
      type:Number
    },
    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props) {
    return useVoirApplicationMethode(props.id, props.lectureSeule);
  }
};
</script>

<style scoped>

.modal-container {
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

}

.titreAfficher{
  background-color: #66c1bf !important;
  border-radius: 30px;
  color: black;
  text-align: center;
  margin-bottom: 1em;
  font-size: 2px;
  vertical-align: center;
}

.formAfficher{
  background-color: #c4c0b3;
}
.afficher{
  margin-top: -1em;
}

</style>