<template>
  <div>
    <b-card v-if="show" class="modal-container">
      <form @submit.prevent="emitInsertEtape" id="addEtape">
        <div>
          <p><strong>I. Caractéristiques de l'étape de traitement</strong></p>
          <b-card class="formModal mr-4 ml-4">
            <b-row>
              <b-col cols="3">
                <b-form-group class="labelTitre" id="input-group-1" style="text-align: start" label-size="sm" label="ID Etape:" label-for="input-1">
                  <b-form-input id="input-1" style="width: 60%" size="sm" type="text">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Numéro*:" label-for="input-2">
              <Field name="noEtape" type="text" class="form-control" />
              <ErrorMessage name="noEtape" v-model="noEtape" class="text-danger" />
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-3" label-size="sm" label="Nom*:" label-for="input-3">
              <Field name="nom" type="text" class="form-control" />
              <ErrorMessage name="nom" v-model="nom" class="text-danger" />
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-4" label-size="sm" label="Type methode traitement:" label-for="input-4">
              <b-form-select id="input-4" size="sm" v-model="typeMethTrait.code">
                <option :value="null"></option>
                <option v-for="type in typeMethTraits" v-bind:value="type.code" v-bind:key="type.code">{{type.signification}}</option>
              </b-form-select>
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-5" label-size="sm" label="Résumé:" label-for="textarea">
              <b-form-textarea
                  id="textarea"
                  v-model="descriptionMethTrait"
                  rows="3"
                  max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-card>
        </div>
        <div class="bouton">
          <b-button pill type="submit" class="save m-2">Enregistrer les caractéristiques de l'etape</b-button>
        </div>
      </form>
    </b-card>
    <div v-if="showModalDonnees && idEtape !== 0" id="donneesEtapes" class="donnee mt-3">
      <ModalDonnee
          :donnees="donnees"
          :idEtape="idEtape">
      </ModalDonnee>
    </div>
    <div v-if="idEtape !== 0 && showAddMethEtape" class="bouton">
      <b-button pill type="submit" @click="insertEtapeFromMethode(etapeToAdd)" class="valid m-2" id="saveEtape">Enregistrer l'etape</b-button>
    </div>
  </div>
</template>

<script>

import { Field, ErrorMessage } from 'vee-validate';
import ModalDonnee from "@/components/Methode/Etape/Donnees/TypesDonneesList.vue";
import useAjoutEtape from "@/composition/Etape/ajoutEtape";
export default {
  name: 'InsertEtapeMethode',
  components: {
    ModalDonnee,
  Field,
  ErrorMessage,
 },

  props:{
    idMethodeEtape:{
      type:Number
    },
    etapesMeth:{
      type:Array,
      required:true
    },
    idApp:{
      type:Number,
      required:true
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props, {emit}) {
    return useAjoutEtape({props, emit});
  }
};
</script>

<style scoped>

.modal-container {
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

}

.labelTitre{
  font-size: 14px;
}

.save{
  color: white !important;
  font-size: 15px;
  text-align: right;
  margin:auto;
  background-color: #00a3a6 !important;
}
.save:hover{
  color: black !important;
}

</style>